import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function

const PodcastTile = props => {
  const { title, blurb, links, embed, image, href, fullWidth } = props;

  if (fullWidth) {
    return (
      <div className="podcast-tile-full">
        <a href={`${href}`}>
          <h2 className="podcast-tile-full__title">{title}</h2>
          <p className="podcast-tile-full__blurb">{blurb}</p>
        </a>
        <div className="podcast-tile-full__links">
          {/* HANDLE KEYWEE TRACKER */}
          {links.map(link => (
            <a
              target="_blank"
              href={`${link.link}`}
              onClick={e => {
                e.preventDefault();
                if (window.top.kwa) {
                  window.top.kwa('sendCustomFBEvent', 'PodcastPlay');
                }
                window.top.open(`${link.link}`, '_blank');
              }}
            >
              <span className="podcast-tile-full__link">
                <h2 className="podcast-tile__link__text">{link.platform}</h2>
              </span>
            </a>
          ))}
        </div>
        <div className="podcast-tile-full__side-container">
          <a className="podcast-tile-full__imagelink" href={`${href}`}>
            <img
              className="podcast-tile-full__image"
              src={image}
              alt="podcast logo"
            />
          </a>
          <div
            className="podcast-tile-full__embed "
            dangerouslySetInnerHTML={{ __html: embed }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="podcast-tile podcast-tile">
      <a href={`${href}`}>
        <h2 className="podcast-tile-full__title">{title}</h2>
        <p className="podcast-tile-full__blurb">{blurb}</p>
      </a>
      <div className="podcast-tile__links">
        {/* HANDLE KEYWEE TRACKER */}
        {links.map(link => (
          <a
            target="_blank"
            href={`${link.link}`}
            onClick={e => {
              e.preventDefault();
              if (window.top.kwa) {
                window.top.kwa('sendCustomFBEvent', 'PodcastPlay');
              }
              window.top.open(`${link.link}`, '_blank');
            }}
          >
            <span className="podcast-tile-full__link">
              <h2 className="podcast-tile__link__text">{link.platform}</h2>
            </span>
          </a>
        ))}
      </div>
      <a href={`${href}`}>
        <img className="podcast-tile__image" src={image} alt="podcast logo" />
      </a>
      <div dangerouslySetInnerHTML={{ __html: embed }} />
    </div>
  );
};

PodcastTile.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  embed: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  links: PropTypes.shape({ root: PropTypes.array.isRequired }).isRequired
};

export default PodcastTile;
